import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from 'components/layout';
import Helmet from 'react-helmet';
import './index.css';
export default class BetaBadge extends React.Component {
  render() {
    return (
      <div className="beta-badge   fixed-top2">

        <div
          className="bg-primary py-1 beta-badge2"
          style={{ zIndex: 10, backgroundColor: '#6599f9!important' }}
        >
          <div className="container text-white ">
            <small>
              &nbsp;&nbsp;
              <BetaBadgeMessage />
            </small>
          </div>
        </div>
      {process.env.GATSBY_ACTIVE_ENV=="demo" || 
                    process.env.REACT_APP_BUILD_ENV=="demo" || 
                    process.env.NODE_ENV=="development"  
                    ?
                      <>

<DemoBadge /> 
      </> : <></>}
        </div>
    );
  }
}

export class BetaBadgeMessage extends React.Component {
  render() {
    return (
      <>
        Welcome! Thank you for visiting! We are still in beta
        {/* <Link  className=" text-underline text-white " to="/beta"><u>beta</u></Link> */}
        . Feel free to submit feedback (see feedback icon on lower right).
      </>
    );
  }
}

export class DemoBadge extends React.Component {
  render() {
    return (
      <div
        className="bg-dark py-1 demo-badge2  fixed-top2"
        style={{ zIndex: 10 }}
      >
        <div className="container text-white ">
          <small>
            &nbsp;&nbsp;
            <DemoBadgeMessage />
          </small>
        </div>
      </div>
    );
  }
}

export class DemoBadgeMessage extends React.Component {
  render() {
    return (
      <>DEMO SERVER - This is non-public, demo server for
        authorized individuals only.
      </>
    );
  }
}
