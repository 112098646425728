/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"

import Helmet from "react-helmet"
import Header from "./header.js"
import "bootstrap/dist/css/bootstrap.min.css"
import "../../static/styles/main.scss"
import "../../static/theme/css/theme-blue.css"
import "../../static/theme/css/icons.min.css"
import logo from "../../static/images/dptlogo.png"
import iconTwitter from "../../static/images/icon-twitter.svg"
import iconLinkedin from "../../static/images/icon-linkedin.svg"
import iconGitHub from "../../static/images/icon-github.svg"
import iconInstagram from "../../static/images/icon-instagram.svg"

import '@fortawesome/fontawesome-free';
// import "../../static/styles/custom.scss"


// import ScrollToTop from './ScrollToTop';

const Head = ({ children }) => (
  <>
  
    <Helmet>
      <link rel="icon" href="/favicon.ico" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      {/* <script
        src="https://cdnjs.cloudflare.com/ajax/libs/holder/2.9.7/holder.min.js"
        integrity="sha256-CPLvnJ0LSBm+lJAUh4bBMpJ1lUa3QsTfdgCAUHyBv2w="
        crossOrigin="anonymous"
      /> */}


      {/* <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO" crossorigin="anonymous" /> */}
 {/*
      <link
        rel="stylesheet"
        type="text/css"
        href="//cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.1.0/cookieconsent.min.css"
      />
      <script src="//cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.1.0/cookieconsent.min.js" />
      <script>
        {`
            window.addEventListener("load", function(){
            window.cookieconsent.initialise({
            "palette": {
                "popup": {
                "background": "#252e39"
                },
                "button": {
                "background": "#14a7d0"
                }
            },
            "content": {
                "href": "https://www.iubenda.com/privacy-policy/20689920"
            }
            })});`}
      </script> */}
      {/* <script type="text/javascript">{`(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);`}</script> */}
      {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
      
     
{/* 
      {process.env.NODE_ENV == 'production' ? ( 
                        <>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=UA-132869296-1"
      />
      <script>
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-132869296-1');
        `}
      </script>

                        </>
                      ) : null} */}

      {/* <!-- Facebook Pixel Code --> 
    <script>{`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '310282202958577');
        fbq('track', 'PageView');
        `}
    </script>
    <noscript>{`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=310282202958577&ev=PageView&noscript=1" />`}</noscript>*/}
      {/* <!-- End Facebook Pixel Code --> */}

{/*   

//TODO - Calendly integration use without http request for more security when dpt scans  etc ?
  */}   
      <link href="https://calendly.com/assets/external/widget.css" rel="stylesheet" />
        
<script src="https://calendly.com/assets/external/widget.js" type="text/javascript"></script>




{/* <link href='https://fonts.googleapis.com/css?family=Lato:300,400,700,300italic,400italic' rel='stylesheet' type='text/css'/> */}
        <link href="https://fonts.googleapis.com/css?family=Open+Sans:300italic,400,300,700" rel="stylesheet" type="text/css"/>
        <link href="/theme/css/font-opensans.css" rel="stylesheet" type="text/css"></link>
{/*   

//TODO - font-awesome integration use without http request for more security when dpt scans  etc ?
  */}   

<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css"/>
 
    </Helmet>
  </>
)


const Footer = ({ data, deckMode=false, videoMode=false, simple=false }) => {
  let cssClass="container"
  let cssClassFooterExtended="footer-bar pt-4 my-md-5 pt-md-5 border-top-b"
  if (deckMode || simple) {
    cssClass="container d-none";
  }
    if (videoMode) {
      cssClass="container d-none2";
      cssClassFooterExtended = "d-none";
    }

  return (
    <>
<div className={cssClass}>
    <footer className={cssClassFooterExtended}>
        <div className="row">
          <div className="col-12 col-md"> 
            {/* <small className="d-block mb-3 text-muted">© 2017-2018</small> */}
            <div className="logo">
                    <Link to="/" title={data.site.siteMetadata.title}>
                      <img className="img-fluid" alt="Logo" src={logo} />
                    </Link>
                  </div>

                  <div className="about text-muted">
                    <br/>
                    <br/>
                    <p>{data.site.siteMetadata.footerDescription}</p>
                    <br/>
                    <br/>
                  </div>

          </div>
          <div className="col-6 col-md">
            <h5>Features</h5>
            {/* <h4>LEARN MORE</h4> */}
            <ul className="list-unstyled text-small">
                      <li>
                        <Link to="/solutions/people" title="People" className="text-muted" >
                          For People
                        </Link>
                      </li>
                      <li>
                        <Link to="/solutions/business" title="Business" className="text-muted" >
                          For Business
                        </Link>
                      </li>
                      <li>
                        <Link to="/solutions/providers" title="Providers" className="text-muted" >
                          For Providers
                        </Link>
                      </li>
                      <li>
                        <Link to="/solutions/partners" title="Partners" className="text-muted" >
                          For Partners
                        </Link>
                      </li>
                      <li>
                        <Link to="/what" title="What" className="text-muted" >
                          What
                        </Link>
                      </li>
                      <li>
                        <Link to="/why" title="Why" className="text-muted" >
                          Why?
                        </Link>
                      </li>
                      <li>
                        <Link to="/howitworks" title="How It Works" className="text-muted" >
                          How It Works?
                        </Link>
                      </li>
                     


                      {process.env.GATSBY_ACTIVE_ENV=="demo" || process.env.REACT_APP_BUILD_ENV=="demo" || process.env.NODE_ENV=="development" ? 
<>
                     
                      <li>
                        <Link  className="text-muted" to="/request-report" title={"Request Report"}>
                          Request Report
                        </Link>
                      </li>
                      <li>
                        <Link  className="text-muted" to="/request-demo" title={"Request Demo"}>
                          Request Demo
                        </Link>
                      </li> 
                      <li>
                        <Link  className="text-muted" to="/signup/" title={"Sign Up"}>
                          Sign Up
                        </Link>
                      </li> 


                      {/* <li><a href={data.site.siteMetadata.blogUrl}>Blog</a></li> */}

                      </> : <></>}
 
            </ul>
          </div>
          <div className="col-6 col-md">
            <h5>Resources</h5>
              {/* <h4>ENGAGE</h4> */}
            <ul className="list-unstyled text-small"> 
  
                      {(process.env.GATSBY_ACTIVE_ENV=="demo" || process.env.REACT_APP_BUILD_ENV=="demo" || process.env.NODE_ENV=="development" ? 
<>
                      <li>
                        <Link  className="text-muted" to="/services/" title={"Explore Services"}>
                         Explore
                        </Link>
                      </li>
                      <li>
                        <Link  className="text-muted" to="/help/" title={"Explore Help"}>
                         Help
                        </Link>
                      </li><li>
                        <Link  className="text-muted" to="/help/faq" title={"Explore FAQ"}>
                         FAQ
                        </Link>
                      </li>
                      {/* <li>
                        <Link  className="text-muted" to="/news/" title={"Explore News"}>
                         News
                        </Link>
                      </li> */}
                      <li>
                        <Link  className="text-muted" to="/blog/" title={"Explore Blog"}>
                         Blog
                        </Link>
                      </li>
                      <li>
                        <Link  className="text-muted" to="/videos/" title={"Explore Videos"}>
                         Videos
                        </Link>
                      </li>
                      <li>
                        <Link  className="text-muted" to="/papers/" title={"Explore Papers"}>
                         Papers
                        </Link>
                      </li>
                      <li>
                        <Link  className="text-muted" to="/presentations/" title={"Explore Presentations"}>
                        Presentations
                        </Link>
                      </li>
                      <li>
                          <Link to="/problems/" className="text-muted" >
                           Problems
                          </Link>
                        </li>  
                      <li>
                        <Link to="/solutions/" className="text-muted"  >
                         Solutions
                        </Link>
                      </li> 
                      

                      <li>
                        <Link to="/standards/" className="text-muted"  >
                         Standards
                        </Link>
                      </li> 
                      
                      <li>
                        <Link to="/risks/" className="text-muted"  >
                         Risks
                        </Link>
                      </li> 
                      <li>
                        <Link  className="text-muted" to="/consumers/" title={"Why for Consumers?"}>
                          Why for Consumers?
                        </Link>
                      </li> 
                      <li>
                        <Link  className="text-muted" to="/why/whynow/" title={"Why for Organizations?"}>
                          Why for Organizations?
                        </Link>
                      </li> 
                      <li>
                        <Link  className="text-muted" to="/sitemap" title={"Sitemap"}>
                          Sitemap
                        </Link>
                      </li> 
                      </>:    <li>
                        <Link  className="text-muted" to="/" title={"Sign Up"}>
                          Sign Up
                        </Link>
                      </li> )}

                  

                      {/* <li><a className={"links__special"} href={data.site.siteMetadata.feedbackUrl} target={"_blank"} title={"We look forward to receiving your great feedback"}>Feedback</a></li> */}
            
            </ul>
          </div>
          <div className="col-6 col-md">
            <h5>Learn More</h5>

            <ul className="list-unstyled text-small">
              
            <li>
                        <Link to="/about" title="About Us" className="text-muted" >
                          About
                        </Link>
                      </li>
                      
              {process.env.GATSBY_ACTIVE_ENV=="demo" || 
                      process.env.REACT_APP_BUILD_ENV=="demo" || 
                      process.env.NODE_ENV=="development" 
                      ?
                        <>
                       
                      <li>
                        <Link to="/company/our-story" title="About Us" className="text-muted" >
                          Our Story
                        </Link>
                      </li>
                      <li>
                        <Link to="/company/trust" title="Trusting Us" className="text-muted" >
                         Trust
                        </Link>
                      </li>
                      
                      <li>
                        <Link to="/testimonials" title="Testimonials" className="text-muted" >
                         Testimonials
                        </Link>
                      </li>
                      <li>
                        <Link to="/company/vision/" className="text-muted"  >
                         Vision
                        </Link>
                      </li>  
                      <li>
                        <Link to="/about/difference/" className="text-muted"   >
                         Difference
                        </Link>
                      </li> 
                      <li>
                        <Link to="/company/" className="text-muted"  >
                         Company
                        </Link>
                      </li>  
                      
                      <li>
                        <Link to="/company/team/" className="text-muted" >
                         Team
                        </Link>
                      </li> 
                      
                      <li>
                        <Link to="/partners/" className="text-muted"  >
                         Partners
                        </Link>
                      </li>   
                      
                      <li>
                        <Link to="/feedback" title={"Feedback"}  className="text-muted">
                          Feedback
                        </Link>
                      </li>
{/*                       
              <li><a className="text-muted" href="#">Team</a></li>
              <li><a className="text-muted" href="#">Locations</a></li>  */}
                      </> : <></>}

         
                      <li>
                        <Link to="/contact" title={"Contact Us"}  className="text-muted">
                          Contact
                        </Link>
                      </li>
                      <li>
                        <Link to="/privacy" title={"Privacy Policy"}  className="text-muted">
                          Privacy
                        </Link>
                      </li>
                      <li>
                        <Link to="/terms" title={"Terms Of Use"}  className="text-muted">
                          Terms Of Use
                        </Link>
                      </li>
                    </ul>
 

          </div>



              
          {process.env.GATSBY_ACTIVE_ENV=="demo" || 
                      process.env.REACT_APP_BUILD_ENV=="demo" || 
                      process.env.NODE_ENV=="development" 
                      ?
                        <>
          <div className="col-6 col-md">  
            <h5>Social</h5>

                    <div className={"social d-none2"}>
                    <a className="pl-2"
                      href={data.site.siteMetadata.twitterUrl}
                      target={"_blank"}
                      title={"Twitter"}
                    >
                      <img alt={"Twitter"} src={iconTwitter} />
                    </a>
                    <a className="pl-2"
                      href={data.site.siteMetadata.linkedInUrl}
                      target={"_blank"}
                      title={"LinkedIn"}
                    >
                      <img alt={"LinkedIn"} src={iconLinkedin} />
                    </a>
                    {/* <a href="https://github.com/datapolicytrust" target={"_blank"} title={"GitHub"}><img alt={"GitHub"} src={iconGitHub}/></a> */}
                    {/* <a href="https://www.instagram.com/datapolicytrust.com/" target={"_blank"} title={"Instagram"}><img alt={"Instagram"} src={iconInstagram}/></a> */}
                  </div>

          </div>
</> : <></>}
        </div>

        
      </footer>
      <div className="cover-container fixed-bottom pb-3 d-none">

<div className="mastfoot  text-center">
  <div className="inner">
    <div className={"copyright"}>
      <p>
        <small>
          Copyright {new Date().getFullYear()}. All rights reserved.{" "}
          <a
            href={data.site.siteMetadata.url}
            title={data.site.siteMetadata.title}
          >
            {data.site.siteMetadata.title}
          </a>
          .{/* All rights reserved.   */}{" "}
          <Link to="/privacy" title={"Privacy Policy"}>
            Privacy Policy
          </Link>
        </small>
      </p>
    </div>
  </div>
</div>
</div>
     <footer  className={cssClass}>
          <div className="container footer-bar d-none2"> 

            <div className={"copyright"}>
              <p>
                Copyright {new Date().getFullYear()}{" "}
                <a
                  href={data.site.siteMetadata.url}
                  title={data.site.siteMetadata.title}
                >
                  {data.site.siteMetadata.title}
                </a>
                . All rights reserved.
              </p>
            </div>
          </div>
        </footer>

        </div>
 
   {process.env.GATSBY_ACTIVE_ENV=="demo" || 
                      process.env.REACT_APP_BUILD_ENV=="demo" || 
                      process.env.NODE_ENV=="development" 
                      ?
                        <>
    <FeedbackApp/>      </> : <></>}

       </>
  )
            }

  
import loadable from "@loadable/component"; // npm install @loadable/component
const Feedback = loadable(() => import("feeder-react-feedback/dist/Feedback")); // dynamically load Feedback component
import "feeder-react-feedback/dist/feeder-react-feedback.css"; // import stylesheet

class FeedbackApp extends React.Component {
  render() {
    return <Feedback projectId="60c9187b93029c000416e509" />;
  }
}

const Layout = ({ children, deckMode = false, videoMode=false, simple=false }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            siteTitle
            title
            url
            blogUrl
            appUrl
            getStartedUrl
            twitterUrl
            linkedInUrl
            feedbackUrl
            description
            metaDescription
            homeDescription
            footerDescription
          }
        }
      }
    `}
    render={data => (
      <>
        <Head /> 
          <Header simple={simple} siteTitle={data.site.siteMetadata.title} data={data} deckMode={deckMode} videoMode={videoMode}/>        
          <main>{children}</main> 
          <Footer simple={simple}  data={data} deckMode={deckMode}  videoMode={videoMode}/> 
       </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
